const RaceState = {
    cups: [],
    refreshCups:false,
    allCups: [],
    refreshAllCups:false,
    cupCategories: [],
    refreshcupCategories:false,
    rounds: [],
    refreshRounds:false,
    roundsResults: [],
    refreshRoundsResults:false,
    nextCup : [],
    refreshNextCup: false,
    race_cups: [],

}
export default RaceState;