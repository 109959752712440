/**
 * Created by ITShpere on 11/5/2021.
 */

import StoresState from './state';

import {
    FETCH_WATCH,
    FETCH_WATCH_SUCCESS,
    FETCH_WATCH_FAILURE,
    FETCH_CHANNELS,
    FETCH_CHANNELS_SUCCESS,
    FETCH_CHANNELS_FAILURE
} from './constants'


const watchReducer = (state = StoresState, action) => {
    const type = action.type;
    const payload = action.payload;
    switch (type) {
        case FETCH_WATCH: {
            
            return {
                ...state,
                watch: [],
                refreshWatch: true
            }
        }

        case  FETCH_WATCH_SUCCESS: {
            return {
                ...state,
                watch: payload?.data?.data,
                refreshWatch: false
            }
        }

        case FETCH_WATCH_FAILURE: {
            return {
                ...state,
                refreshWatch: false
            }
        }

        case FETCH_CHANNELS: {
            
            return {
                ...state,
                channels: [],
                refreshChannels: true
            }
        }

        case  FETCH_CHANNELS_SUCCESS: {
            return {
                ...state,
                channels: payload?.data?.data,
                refreshChannels: false
            }
        }

        case FETCH_CHANNELS_FAILURE: {
            return {
                ...state,
                refreshChannels: false
            }
        }
        default:{
            return{
                ...state
            }
        }
    }
};

export default watchReducer;
