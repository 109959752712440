const FETCH_CUPS = "[CUPS] fetch";
const FETCH_CUPS_SUCCESS = "[CUPS] fetch success";
const FETCH_CUPS_FAILURE = "[CUPS] fetch failure";

const FETCH_RACES_PAGE_CUPS = "FETCH_RACES_PAGE_CUPS";
const FETCH_RACES_PAGE_CUPS_SUCCESS = "FETCH_RACES_PAGE_CUPS_SUCCESS";
const FETCH_RACES_PAGE_CUPS_FAILURE = "FETCH_RACES_PAGE_CUPS_FAILURE";

const FETCH_ALLCUPS = "[ALLCUPS] fetch";
const FETCH_ALLCUPS_SUCCESS = "[ALLCUPS] fetch success";
const FETCH_ALLCUPS_FAILURE = "[ALLCUPS] fetch failure";

const FETCH_ROUNDS = "[ROUNDS] fetch";
const FETCH_ROUNDS_SUCCESS = "[ROUNDS] fetch success";
const FETCH_ROUNDS_FAILURE = "[ROUNDS] fetch failure";

const FETCH_ROUNDSRESULTS = "[ROUNDSRESULTS] fetch";
const FETCH_ROUNDSRESULTS_SUCCESS = "[ROUNDSRESULTS] fetch success";
const FETCH_ROUNDSRESULTS_FAILURE = "[ROUNDSRESULTS] fetch failure";
const RESET_RESULTS_DATA = "[ROUNDSRESULTS] reset failure"

const FETCH_NEXTCUP = "[NEXTCUP] fetch";
const FETCH_NEXTCUP_SUCCESS = "[NEXTCUP] fetch success";
const FETCH_NEXTCUP_FAILURE = "[NEXTCUP] fetch failure";

const FETCH_CUPCATEGORIES = "[CUPCATEGORIES] fetch";
const FETCH_CUPCATEGORIES_SUCCESS = "[CUPCATEGORIES] fetch success";
const FETCH_CUPCATEGORIES_FAILURE = "[CUPCATEGORIES] fetch failure";

const GET_RACE_CUPS = "GET_RACE_CUPS";
const GET_RACE_CUPS_SUCCESS = "GET_RACE_CUPS_SUCCESS";
const GET_RACE_CUPS_FAILURE = "GET_RACE_CUPS_FAILURE";

export {
    FETCH_CUPS,
    FETCH_CUPS_SUCCESS,
    FETCH_CUPS_FAILURE,

    FETCH_ALLCUPS,
    FETCH_ALLCUPS_SUCCESS,
    FETCH_ALLCUPS_FAILURE,

    FETCH_CUPCATEGORIES,
    FETCH_CUPCATEGORIES_SUCCESS,
    FETCH_CUPCATEGORIES_FAILURE,

    FETCH_ROUNDS,
    FETCH_ROUNDS_SUCCESS,
    FETCH_ROUNDS_FAILURE,

    FETCH_ROUNDSRESULTS,
    FETCH_ROUNDSRESULTS_SUCCESS,
    FETCH_ROUNDSRESULTS_FAILURE,
    RESET_RESULTS_DATA,

    FETCH_NEXTCUP,
    FETCH_NEXTCUP_SUCCESS,
    FETCH_NEXTCUP_FAILURE,

    FETCH_RACES_PAGE_CUPS,
    FETCH_RACES_PAGE_CUPS_SUCCESS,
    FETCH_RACES_PAGE_CUPS_FAILURE,
    
    GET_RACE_CUPS,
    GET_RACE_CUPS_SUCCESS,
    GET_RACE_CUPS_FAILURE
}

