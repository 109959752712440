const CREATE_TELER_PAYMENT ="[PAYMENT] create payment";
const CREATE_TELER_PAYMENT_SUCCESS ="[PAYMENT] create payment success";
const CREATE_TELER_PAYMENT_FAILURE ="[PAYMENT] create payment failure";

const CHECK_TELER_PAYMENT ="[PAYMENT] check payment";
const CHECK_TELER_PAYMENT_SUCCESS ="[PAYMENT] check payment success";
const CHECK_TELER_PAYMENT_FAILURE ="[PAYMENT] check payment failure";

const PAYMENT_METHOD ="[PAYMENT_METHOD] payment method";
const PAYMENT_METHOD_SUCCESS ="[PAYMENT_METHOD] payment method success";
const PAYMENT_METHOD_FAILURE ="[PAYMENT_METHOD]  payment method failure";

export {
    CREATE_TELER_PAYMENT,
    CREATE_TELER_PAYMENT_SUCCESS,
    CREATE_TELER_PAYMENT_FAILURE,

    CHECK_TELER_PAYMENT,
    CHECK_TELER_PAYMENT_SUCCESS,
    CHECK_TELER_PAYMENT_FAILURE,


    PAYMENT_METHOD,
    PAYMENT_METHOD_SUCCESS,
    PAYMENT_METHOD_FAILURE
};