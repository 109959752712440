/* eslint-disable */ 


import StoresState from './state';

import {

    FETCH_CITIES,
    FETCH_CITIES_SUCCESS,
    FETCH_CITIES_FAILURE,

    FETCH_TERMS,
    FETCH_TERMS_SUCCESS,
    FETCH_TERMS_FAILURE,

    FETCH_CITYCUPS,
    FETCH_CITYCUPS_SUCCESS,
    FETCH_CITYCUPS_FAILURE,

    FETCH_EVENTS,
    FETCH_EVENTS_SUCCESS,
    FETCH_EVENTS_FAILURE,

    FETCH_EVENTSEARCH,
    FETCH_EVENTSEARCH_SUCCESS,
    FETCH_EVENTSEARCH_FAILURE,

    FETCH_CITIESSEARCH,
    FETCH_CITIESSEARCH_SUCCESS,
    FETCH_CITIESSEARCH_FAILURE,

    FETCH_PACKAGESSEARCH,
    FETCH_PACKAGESSEARCH_SUCCESS,
    FETCH_PACKAGESSEARCH_FAILURE,

    FETCH_SINGLEEVENT,
    FETCH_SINGLEEVENT_SUCCESS,
    FETCH_SINGLEEVENT_FAILURE,

    FETCH_SLIDERS,
    FETCH_SLIDERS_SUCCESS,
    FETCH_SLIDERS_FAILURE,

    CREATE_PAYMENT,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_FAILURE,

    FETCH_ABOUTUS,
    FETCH_ABOUTUS_SUCCESS,
    FETCH_ABOUTUS_FAILURE,

    FETCH_CUPSHERO,
    FETCH_CUPSHERO_SUCCESS,
    FETCH_CUPSHERO_FAILURE,

    FETCH_PACKAGEHERO,
    FETCH_PACKAGEHERO_SUCCESS,
    FETCH_PACKAGEHERO_FAILURE,

    FETCH_CITYHERO,
    FETCH_CITYHERO_SUCCESS,
    FETCH_CITYHERO_FAILURE

} from './constants'

const initState = {
    sponsors: [],
};

const homeReducer = (state = StoresState, action) => {
    const type = action.type;
    const payload = action.payload;
    switch (type) {
        
        case FETCH_CITIES: {
            
            return {
                ...state,
                // cities: [],
                refreshCities: true
            }
            break;
        }

        case  FETCH_CITIES_SUCCESS: {
            return {
                ...state,
                // cities: payload?.data?.cities,
                cities: payload?.options?.loadmore ? [
                    ...state.faq,
                    ...payload?.cities?.data?.cities
                ] : payload?.cities?.data?.cities,
                citiesTotalPage:payload?.cities?.data?.totalPages,
                citiesCurentPage:payload?.cities?.data?.currentPage,
                refreshCities: false
            }
            break;
        }

        case FETCH_CITIES_FAILURE: {
            return {
                ...state,
                refreshCities: false
            }
            break;
        }

        case FETCH_TERMS: {

            return {
                ...state,
                // cities: [],
                refreshTerms: true
            }
            break;
        }

        case  FETCH_TERMS_SUCCESS: {
            return {
                ...state,
                terms: payload?.terms?.data,
            }
            break;
        }

        case FETCH_TERMS_FAILURE: {
            return {
                ...state,
                refreshTERMS: false
            }
            break;
        }

        case FETCH_CITYCUPS: {
            
            return {
                ...state,
                cityCups: [],
                refreshCityCups: true
            }
            break;
        }

        case  FETCH_CITYCUPS_SUCCESS: {
            return {
                ...state,
                cityCups: payload?.data?.cities,
                refreshCityCups: false
            }
            break;
        }

        case FETCH_CITYCUPS_FAILURE: {
            return {
                ...state,
                refreshCityCups: false
            }
            break;
        }



        case FETCH_EVENTS: {
            
            return {
                ...state,
                // events: [],
                refreshEvents: true
            }
            break;
        }

        case  FETCH_EVENTS_SUCCESS: {
            return {
                ...state,
                // events: payload?.data?.cups,
                events: payload?.options?.loadmore ? [
                    ...state.faq,
                    ...payload?.events?.data?.cups
                ] : payload?.events?.data?.cups,
                eventsTotalPage:payload?.events?.data?.totalPages,
                eventsCurentPage:payload?.events?.data?.currentPage,
                refreshEvents: false
            }
            break;
        }

        case FETCH_EVENTS_FAILURE: {
            return {
                ...state,
                refreshEvents: false
            }
            break;
        }

        case FETCH_EVENTSEARCH: {
            
            return {
                ...state,
                eventSearch: [],
                citiesSearch: [],
                packagesSearch: [],
                searchState: true
            }
            break;
        }

        case  FETCH_EVENTSEARCH_SUCCESS: {
            return {
                ...state,
                eventSearch: payload?.data?.cups,
                searchState: false
            }
            break;
        }

        case FETCH_EVENTSEARCH_FAILURE: {
            return {
                ...state,
                searchState: false
            }
            break;
        }

        case FETCH_CITIESSEARCH: {
            
            return {
                ...state,
                citiesSearch: [],
                citiesSearchState: true
            }
            break;
        }

        case  FETCH_CITIESSEARCH_SUCCESS: {
            return {
                ...state,
                citiesSearch: payload?.data?.cities,
                citiesSearchState: false
            }
            break;
        }

        case FETCH_CITIESSEARCH_FAILURE: {
            return {
                ...state,
                citiesSearchState: false
            }
            break;
        }

        case FETCH_PACKAGESSEARCH: {
            
            return {
                ...state,
                packagesSearch: [],
                packagesSearchState: true
            }
            break;
        }

        case  FETCH_PACKAGESSEARCH_SUCCESS: {
            return {
                ...state,
                packagesSearch: payload?.data?.package,
                packagesSearchState: false
            }
            break;
        }

        case FETCH_PACKAGESSEARCH_FAILURE: {
            return {
                ...state,
                packagesSearchState: false
            }
            break;
        }

        case FETCH_SINGLEEVENT: {
            
            return {
                ...state,
                singleEvent: [],
                refreshSingleEvent: true
            }
            break;
        }

        case  FETCH_SINGLEEVENT_SUCCESS: {
            return {
                ...state,
                singleEvent: payload?.data?.cups,
                refreshSingleEvent: false
            }
            break;
        }

        case FETCH_SINGLEEVENT_FAILURE: {
            return {
                ...state,
                refreshSingleEvent: false
            }
            break;
        }

        case FETCH_SLIDERS: {
            
            return {
                ...state,
                sliders: [],
                refreshSliders: true
            }
            break;
        }

        case  FETCH_SLIDERS_SUCCESS: {
           
            return {
                ...state,
                sliders: payload?.data?.data,
                refreshSliders: false

            }
            break;
        }

        case FETCH_SLIDERS_FAILURE: {
            return {
                ...state,
                refreshSliders: false

            }
            break;
        }

       
        case CREATE_PAYMENT: {
           
            return {
                ...state,
                createPayemnt: [],

            }
            break;
        }

        case CREATE_PAYMENT_SUCCESS: {

            return {
                ...state,
                createPayemnt: payload?.data?.data?.body,
                paymentCreated: true,
            }
            break;
        }

        case CREATE_PAYMENT_FAILURE: {
            return {
                ...state,
                paymentCreated: false
            }
            break;
        }

        case FETCH_ABOUTUS: {
           
            return {
                ...state,
                aboutUs: [],
                refreshAboutUs: true
            }
            break;
        }

        case FETCH_ABOUTUS_SUCCESS: {
            return {
                ...state,
                aboutUs: payload?.data?.data,
                refreshAboutUs: false
            }
            break;
        }

        case FETCH_ABOUTUS_FAILURE: {
            return {
                ...state,
                refreshAboutUs: false
            }
            break;
        }

        case FETCH_CUPSHERO: {
           
            return {
                ...state,
                cupsHero: [],
                refreshCupsHero: true
            }
            break;
        }

        case FETCH_CUPSHERO_SUCCESS: {
            return {
                ...state,
                cupsHero: payload?.data?.data,
                refreshCupsHero: false
            }
            break;
        }

        case FETCH_CUPSHERO_FAILURE: {
            return {
                ...state,
                refreshCupsHero: false
            }
            break;
        }

        case FETCH_PACKAGEHERO: {
           
            return {
                ...state,
                packageHero: [],
                refreshPackageHero: true
            }
            break;
        }

        case FETCH_PACKAGEHERO_SUCCESS: {
            return {
                ...state,
                packageHero: payload?.data?.data,
                refreshPackageHero: false
            }
            break;
        }

        case FETCH_PACKAGEHERO_FAILURE: {
            return {
                ...state,
                refreshPackageHero: false
            }
            break;
        }

        case FETCH_CITYHERO: {
           
            return {
                ...state,
                cityHero: [],
                refreshCityHero: true
            }
            break;
        }

        case FETCH_CITYHERO_SUCCESS: {
            return {
                ...state,
                cityHero: payload?.data?.data,
                refreshCityHero: false
            }
            break;
        }

        case FETCH_CITYHERO_FAILURE: {
            return {
                ...state,
                refreshCityHero: false
            }
            break;
        }




        default:{
            return{
                ...state
            }
            break;
        }
    }
};

export default homeReducer;
