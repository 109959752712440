/**
 * Created by ITShpere on 11/5/2021.
 */
const FETCH_WATCH = '[WATCH] fetch';
const FETCH_WATCH_SUCCESS = '[WATCH] fetch success';
const FETCH_WATCH_FAILURE = '[WATCH] fetch failure';
const FETCH_CHANNELS = '[CHANNELS] fetch ';
const FETCH_CHANNELS_SUCCESS= '[CHANNELS] fetch success';
const FETCH_CHANNELS_FAILURE= '[CHANNELS] fetch failure';
export{
    FETCH_WATCH,
    FETCH_WATCH_SUCCESS,
    FETCH_WATCH_FAILURE,
    FETCH_CHANNELS,
    FETCH_CHANNELS_SUCCESS,
    FETCH_CHANNELS_FAILURE
};
