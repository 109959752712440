import axios from 'axios';
import CFG from '../constant/CFG';

const request = async ({
    url,
    method,
    params,
    headers,
}) => {
    try {
        // axios.defaults.withCredentials = true
        const fullURL = `${CFG.ticketurl}${url}`;
        return new Promise((resolve, reject) => {
            const timeOutId =  setTimeout(() => {
                console.error('Timeout, Server is not responding')
                reject(new Error('Timeout, Server is not responding'))
                // reject()
            }, 10000) // After 10 seconds if there is no response from API the request will stopped.

            let modfiedHeaders = {
                ...headers,
                "Accept":"*/*",
            };
             //add withCredentials:true before push to beta and dev for OTP Cors
             axios[method](fullURL, method !== "get" ? params : {headers: modfiedHeaders}, {withCredentials: true}, { headers: modfiedHeaders }).then((response) => {
            // axios[method](fullURL, method !== "get" ? params : {headers: modfiedHeaders}, { headers: modfiedHeaders }).then((response) => {
                clearTimeout(timeOutId)
                // console.log(response);
                resolve(response);
            }).catch((error) => {
                clearTimeout(timeOutId)
                reject({
                    status: error?.response?.status,
                    error: error?.response,
                    isError: true
                });
            });

        });
    } catch (error) {
        console.log({ error });
    }
}

export default request;