import request from '../../services/service';


const fetchCitiesRequest = async (page,limit)  => {
   
    return await request({
        url:  `cities?&page=${page}&limit=${limit}`,
        method: "get",
    });
};

const fetchTermsRequest = async ()  => {

    return await request({
        url:  `terms`,
        method: "get",
    });
};

const fetchCityCupsRequest = async (id) => {
    return await request({
        url:  `cities/${id}/cups`,
        method: "get",
    });
};

const fetchSingleEventRequest = async (id) => {
    return await request({
        url:  `cups?id=${id}`,
        method: "get",
    });
};

const fetchEventsRequest = async (page,limit)  => {
   
    return await request({
        url:  `cups?&page=${page}&limit=${limit}`,
        method: "get",
    });
};

const fetchEventSearchRequest = async (name,lang) => {
   
    return await request({
        url:  `cups?search=${name}&lang=${lang}`,
        method: "get",
    });
};

const fetchCitiesSearchRequest = async (name,lang) => {
   
    return await request({
        url:  `cities?search=${name}&lang=${lang}`,
        method: "get",
    });
};

const fetchPackagesSearchRequest = async (name,lang) => {
   
    return await request({
        url:  `packages?search=${name}&lang=${lang}`,
        method: "get",
    });
};

const fetchSlidersRequest = async () => {
   
    return await request({
        url:  `get-home-slider`,
        method: "get",
    });
};

const createPaymentRequest = async (params) => {
    return await request({
        url: 'payment/create/session',
        method: "post",
        params,
       
    });
};

const fetchAboutUsRequest = async () => {
   
    return await request({
        url:  `about-us`,
        method: "get",
    });
};

const fetchCupsHeroRequest = async () => {
   
    return await request({
        url:  `cups/image-banner`,
        method: "get",
    });
};

const fetchCityHeroRequest = async () => {
   
    return await request({
        url:  `cities/image-banner`,
        method: "get",
    });
};

const fetchPackageHeroRequest = async () => {
   
    return await request({
        url:  `packages/image-banner`,
        method: "get",
    });
};

export {
    fetchCitiesRequest,
    fetchTermsRequest,
    fetchCityCupsRequest,
    fetchSingleEventRequest,
    fetchSlidersRequest,
    createPaymentRequest,
    fetchEventsRequest,
    fetchEventSearchRequest,
    fetchCitiesSearchRequest,
    fetchPackagesSearchRequest,
    fetchAboutUsRequest,
    fetchCupsHeroRequest,
    fetchCityHeroRequest,
    fetchPackageHeroRequest
}