import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import contendersReducer from '../contenders/reducer';
import watchReducer from '../watch/reducer';
import videoPageReducer from '../videos/reducer';
import homeReducer from '../home/reducer';
import {combineReducers} from "redux";
import contactReducer from "../contact/reducer";
import {createMultilanguageReducer} from "redux-multilanguage";
import ticketReducer from "../ticket/reducer";
import raceReducer from '../races/reducer';
import paymentReducer from "../payment/reducer";

const rootReducer = combineReducers({
    multilanguage: createMultilanguageReducer({currentLanguageCode: "en"}),
    currencyData: currencyReducer,
    cartData: cartReducer,
    wishlistData: wishlistReducer,
    compareData: compareReducer,
    ticketReducer,
    paymentReducer,
    contendersData:contendersReducer,
    watchReducer,
    videoMediaData : videoPageReducer,
    homeReducer,
    contactReducer,
    raceReducer
});

export default rootReducer;
