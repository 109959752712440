import StoresState from './state';

import {
    FETCH_CONTACT,
    FETCH_CONTACT_SUCCESS,
    FETCH_CONTACT_FAILURE,

    ADD_MESSAGE,
    ADD_MESSAGE_SUCCESS,
    ADD_MESSAGE_FAILURE,

} from './constants'

const contactReducer = (state = StoresState, action) => {
    const type = action.type;
    const payload = action.payload;
    switch (type) {
        case FETCH_CONTACT: {

            return {
                ...state,
                contact: [],
                refreshContact: true
            }
        }
        case FETCH_CONTACT_SUCCESS: {
            return {
                ...state,
                contact: payload?.data?.data,
                refreshContact: false
            }
        }

        case FETCH_CONTACT_FAILURE: {
            return {
                ...state,
                refreshContact: false
            }
        }

        case ADD_MESSAGE: {

            return {
                ...state,
                addContactMessage: {},
            }
        }
        case ADD_MESSAGE_SUCCESS: {
            return {
                ...state,
                contact: payload?.data?.data,
                messageAdded: true,
                messageError: "no"
            }
        }

        case ADD_MESSAGE_FAILURE: {
            return {
                ...state,
                messageAdded: false,
                messageError: payload,
                
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
};

export default contactReducer;