

import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy, useState } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { useTranslation } from 'react-i18next';
import { LangContext } from "./components/LangContext";
import TagManager from 'react-gtm-module'
import TelerPauemnt from "./pages/TelerPayement";


const HomeCup = lazy(() => import("./pages/home/Home"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const EventsItemPage = lazy(() => import("./pages/events/EventsItemPage"));
const AllEvents = lazy(() => import("./pages/AllEvents"));
const CityEvents = lazy(() => import("./pages/CityEvents"));
const AllCities = lazy(() => import("./pages/AllCities"));
const AllTickets = lazy(() => import("./pages/AllTickets"));
const Packages = lazy(() => import("./pages/tickets/tickets"));
const Invoice = lazy(() => import("./pages/other/Invoice"));
const PackagesCups = lazy(() => import("./pages/tickets/CupsTickets"));
const Product = lazy(() => import("./pages/tickets/Product"));
const Checkout = lazy(() => import("./pages/shop/Checkout"));
const Otp = lazy(() => import("./pages/shop/Otp"));
const Payment = lazy(() => import("./pages/shop/Payment"));
const Contact = lazy(() => import("./pages/other/Contact"));
const AbouUsPage = lazy(() => import("./pages/other/AboutUs"));
const LandingPage = lazy(() => import("./pages/LandingPage"));
const TermsPage = lazy(() => import("./pages/Terms"));
const TelerPayement = lazy(() => import("./pages/TelerPayement"));

const tagManagerArgs = {
    gtmId: 'G-48XX9V1Z5X'
}
TagManager.initialize(tagManagerArgs)

const tagManagerArgs2 = {
    gtmId: 'GTM-5K9KM6D'
}
TagManager.initialize(tagManagerArgs2)


const App = props => {

    const { i18n } = useTranslation();
    document.body.dir = i18n.dir();
    window.dataLayer.push({
        event: 'pageview'
    });

    const [langs, setLangs] = useState("en")

    useEffect(() => {
        if (i18n.dir() === "rtl") {
            setLangs('ar')
        }
        else if (i18n.dir() === "ltr") {
            setLangs('en')
        }
    }, [i18n.dir()])

    useEffect(() => {
        document.body.className = i18n.dir();
    }, [i18n]);

    useEffect(() => { window.process = { ...window.process, }; }, []);

    return (

        <ToastProvider placement="bottom-left">
            <BreadcrumbsProvider>
                <LangContext.Provider value={langs}>
                    <Router>
                        <ScrollToTop>
                            <Suspense
                                fallback={
                                    <div className="flone-preloader-wrapper">
                                        <div className="flone-preloader">
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                }
                            >
                                <Switch>
                                    <Route
                                        exact
                                        path={process.env.PUBLIC_URL + "/"}
                                        component={HomeCup}
                                    />
                                    <Route
                                        path={process.env.PUBLIC_URL + "/home/:lang?"}
                                        component={HomeCup}
                                    />
                                    <Route
                                        path={process.env.PUBLIC_URL + "/home-cup/:lang?"}
                                        component={HomeCup}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/event/:id/:lang?"}

                                        render={routeProps => (

                                            <EventsItemPage {...routeProps} key={routeProps.match.params.id} />
                                        )}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/events/:lang?"}

                                        render={routeProps => (
                                            <AllEvents {...routeProps} key={routeProps.match.params.id} />
                                        )}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/city/events/:id/:lang?"}
                                        render={routeProps => (
                                            <CityEvents {...routeProps} key={routeProps.match.params.id} />
                                        )}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/cup/packages/:id/:lang?"}
                                        render={routeProps => (
                                            <PackagesCups {...routeProps} key={routeProps.match.params.id} />
                                        )}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/cities/:lang?"}

                                        render={routeProps => (
                                            <AllCities {...routeProps} key={routeProps.match.params.id} />
                                        )}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/tickets/:lang?"}

                                        render={routeProps => (
                                            <AllTickets {...routeProps} key={routeProps.match.params.id} />
                                        )}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/packages/:lang?"}
                                        component={Packages}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/product/:id/:lang?"}
                                        render={routeProps => (
                                            <Product {...routeProps} key={routeProps.match.params.id} />
                                        )}
                                    />


                                    <Route
                                        path={process.env.PUBLIC_URL + "/checkout/:lang?"}
                                        component={Checkout}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/payment/:lang?"}
                                        component={Payment}

                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/ticket/:id/:lang?"}

                                        render={routeProps => (
                                            <Invoice {...routeProps} key={routeProps.match.params.id} />

                                        )}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/otp/:lang?"}
                                        component={Otp}

                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/contact/:lang?"}
                                        component={Contact}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/about-us/:lang?"}
                                        component={AbouUsPage}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/landing/:lang?"}
                                         component={LandingPage}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/not-found"}
                                        component={NotFound}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/terms/:lang?"}
                                        component={TermsPage}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/teler-payemnt/:id/:lang?"}
                                        component={TelerPayement}
                                    />

                                    <Route exact component={NotFound} />
                                </Switch>
                            </Suspense>
                        </ScrollToTop>
                    </Router>
                </LangContext.Provider>
            </BreadcrumbsProvider>
        </ToastProvider>
    );
};

App.propTypes = {
    dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
