import request from '../../services/ticketservice';

const fetchPackagesRequest = async (page, limit) => {
    return await request({
        url: `packages?status=notsold&page=${page}&limit=${limit}`,
        method: "get",
    });
};

export const fetchPackagesFilterRequest = async (page, limit, date, type) => {
    return await request({
        url: `packages?status=notsold&page=${page}&limit=${limit}&date=${date}&package-type=${type}`,
        method: "get",
    });
};

const fetchSinglePackagesRequest = async (id) => {

    return await request({
        url: `packages?id=${id}`,
        method: "get",
    });
};

const sendTicketRequest = async (params) => {

    return await request({
        url: `ticket/detail`,
        method: "post",
        params,
    });
};

const addClientRequest = async (params) => {
    return await request({
        url: 'create/client',
        method: "post",
        params,
        withCredentials: true
    });
};

const updateTicketRequest = async (params, lang) => {
    return await request({
        url: `update/tickets/status?lang=${lang}`,
        method: "post",
        params,

    });
};

const updateInvoiceRequest = async (params) => {
    return await request({
        url: 'invoice/status',
        method: "post",
        params,

    });
};

const applyCouponRequest = async (params) => {
    return await request({
        url: 'coupons/apply',
        method: "post",
        params,

    });
};


const invoiceDetailRequest = async (params) => {

    return await request({
        url: `invoice/detail`,
        method: "post",
        params,
    });
};

const fetchPackagesCupsRequest = async (id) => {
    return await request({
        url: `cups/${id}/packages`,
        method: "get",
    });
};


export {
    fetchPackagesRequest,
    fetchSinglePackagesRequest,
    sendTicketRequest,
    addClientRequest,
    updateTicketRequest,
    updateInvoiceRequest,
    applyCouponRequest,
    invoiceDetailRequest,
    fetchPackagesCupsRequest
}