const MainState = {
    cities: [],
    citiesTotalPage:0,
    citiesCurentPage:0,
    refreshCities:false,
    cityCups: [],
    refreshCityCups:false,
    events: [],
    eventsTotalPage:0,
    eventsCurentPage:0,
    refreshEvents:false,
    singleEvent: [],
    refreshSingleEvent:false,
    sliders: [],
    refreshSliders: false,
    createPayemnt: [],
    paymentCreated: false,
    eventSearch : [],
    searchState: false,
    citiesSearch : [],
    citiesSearchState: false,
    packagesSearch : [],
    packagesSearchState: false,
    aboutUs:[],
    refreshAboutUs: false,
    cupsHero:{},
    refreshCupsHero: false,
    cityHero:{},
    refreshCityHero: false,
    packageHero: {},
    refreshPackageHero: false
 }
 export default MainState;