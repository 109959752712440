const FETCH_CONTACT = "[CONTACT] fetch";
const FETCH_CONTACT_SUCCESS = "[CONTACT] fetch success";
const FETCH_CONTACT_FAILURE = "[CONTACT] fetch failure";

const ADD_MESSAGE ="[MESSAGE] send client";
const ADD_MESSAGE_SUCCESS ="[MESSAGE] send message success";
const ADD_MESSAGE_FAILURE ="[MESSAGE] send message failure";

export {
    FETCH_CONTACT,
    FETCH_CONTACT_SUCCESS,
    FETCH_CONTACT_FAILURE,
    
    ADD_MESSAGE,
    ADD_MESSAGE_SUCCESS,
    ADD_MESSAGE_FAILURE
};