import RaceState from './state';

import {
    FETCH_CUPS,
    FETCH_CUPS_SUCCESS,
    FETCH_CUPS_FAILURE,

    FETCH_ALLCUPS,
    FETCH_ALLCUPS_SUCCESS,
    FETCH_ALLCUPS_FAILURE,

    FETCH_CUPCATEGORIES,
    FETCH_CUPCATEGORIES_SUCCESS,
    FETCH_CUPCATEGORIES_FAILURE,

    FETCH_ROUNDS,
    FETCH_ROUNDS_SUCCESS,
    FETCH_ROUNDS_FAILURE,

    FETCH_ROUNDSRESULTS,
    FETCH_ROUNDSRESULTS_SUCCESS,
    FETCH_ROUNDSRESULTS_FAILURE,
    RESET_RESULTS_DATA,

    FETCH_NEXTCUP,
    FETCH_NEXTCUP_SUCCESS,
    FETCH_NEXTCUP_FAILURE,

    FETCH_RACES_PAGE_CUPS,
    FETCH_RACES_PAGE_CUPS_SUCCESS,
    FETCH_RACES_PAGE_CUPS_FAILURE,
} from './contants'

const raceReducer = (state =RaceState, action) => {
    const type = action.type;
    const payload = action.payload;


    switch(type) {
        case FETCH_CUPS: {
           
            return {
                ...state,
                cups: [],
                refreshCups: true
            }
            break;
        }

        case  FETCH_CUPS_SUCCESS: {
            return {
                ...state,
                cups: payload?.data?.data,
                refreshCups: false
            }
            break;
        }

        case FETCH_CUPS_FAILURE: {
            return {
                ...state,
                refreshCups: false
            }
            break;
        }

        case FETCH_ALLCUPS: {
           
            return {
                ...state,
                allCups: [],
                refreshAllCups: true
            }
            break;
        }

        case  FETCH_ALLCUPS_SUCCESS: {
           
            return {
                ...state,
                allCups: payload?.data?.data,
                refreshAllCups: false
            }
            break;
        }

        case FETCH_ALLCUPS_FAILURE: {
            return {
                ...state,
                refreshAllCups: false
            }
            break;
        }

        case FETCH_CUPCATEGORIES: {
            
            return {
                ...state,
                cupCategories: [],
                refreshcupCategories: true
            }
            break;
        }

        case  FETCH_CUPCATEGORIES_SUCCESS: {
            return {
                ...state,
                cupCategories: payload?.data?.data,
                refreshcupCategories: false
            }
              break;
        }

        case FETCH_CUPCATEGORIES_FAILURE: {
            return {
                ...state,
                refreshcupCategories: false
            }
            break;
        }

        case FETCH_ROUNDS: {
            
            return {
                ...state,
                rounds: [],
                refreshRounds: true
            }
            break;
        }

        case  FETCH_ROUNDS_SUCCESS: {
            return {
                ...state,
                rounds: payload?.data?.data,
                refreshRounds: false
            }
            break;
        }

        case FETCH_ROUNDS_FAILURE: {
            return {
                ...state,
                refreshRounds: false
            }
            break;
        }

        case FETCH_ROUNDSRESULTS: {
            
            return {
                ...state,
                roundsResults: [],
                refreshRoundsResults: true
            }
            break;
        }

        case  FETCH_ROUNDSRESULTS_SUCCESS: {
            return {
                ...state,
                roundsResults: payload?.data?.data,
                refreshRoundsResults: false
            }
            break;
        }

        case FETCH_ROUNDSRESULTS_FAILURE: {
            return {
                ...state,
                refreshRoundsResults: false
            }
            break;
        }

        case RESET_RESULTS_DATA: {
            return {
                ...state,
                roundsResults: [],
                refreshRoundsResults: false
            }
            break;
        }

        case FETCH_NEXTCUP: {
            
            return {
                ...state,
                nextCup: [],
                refreshNextCup: true
            }
            break;
        }

        case  FETCH_NEXTCUP_SUCCESS: {
            return {
                ...state,
                nextCup: payload?.data?.data,
                refreshNextCup: false
            }
            break;
        }

        case FETCH_NEXTCUP_FAILURE: {
            return {
                ...state,
                refreshNextCup: false
            }
            break;
        }

        case FETCH_RACES_PAGE_CUPS : {
            return {
                ...state,
                race_page_cups: []
            }
            break;  
        }
        
        case FETCH_RACES_PAGE_CUPS_SUCCESS: {
            return {
                ...state,
                race_page_cups: payload.data
            }
            break;
        }

        case FETCH_RACES_PAGE_CUPS_FAILURE: {
            return {
                ...state,
                race_page_cups: []
            }
            break;
        }


        default: {
            return {
                ...state
            }
        }
    }
}

export default raceReducer;