import StoresState from './state';

import {
    FETCH_PACKAGES,
    FETCH_PACKAGES_SUCCESS,
    FETCH_PACKAGES_FAILURE,

    FETCH_SINGLEPACKAGES,
    FETCH_SINGLEPACKAGES_SUCCESS,
    FETCH_SINGLEPACKAGES_FAILURE,

    SEND_TICKET,
    SEND_TICKET_SUCCESS,
    SEND_TICKET_FAILURE,

    ADD_CLIENT,
    ADD_CLIENT_SUCCESS,
    ADD_CLIENT_FAILURE,

    RESET_CLIENT,

    UPDATE_TICKET,
    UPDATE_TICKET_SUCCESS,
    UPDATE_TICKET_FAILURE,

    UPDATE_INVOICE,
    UPDATE_INVOICE_SUCCESS,
    UPDATE_INVOICE_FAILURE,

    APPLY_COUPON,
    APPLY_COUPON_SUCCESS,
    APPLY_COUPON_FAILURE,

    CREATE_TELER_PAYMENT,
    CREATE_TELER_PAYMENT_SUCCESS,
    CREATE_TELER_PAYMENT_FAILURE,

    CHECK_TELER_PAYMENT,
    CHECK_TELER_PAYMENT_SUCCESS,
    CHECK_TELER_PAYMENT_FAILURE,

    INVOICE_DETAIL,
    INVOICE_DETAIL_SUCCESS,
    INVOICE_DETAIL_FAILURE,

    PAYMENT_METHOD,
    PAYMENT_METHOD_SUCCESS,
    PAYMENT_METHOD_FAILURE,

    FETCH_PACKAGESCUPS,
    FETCH_PACKAGESCUPS_SUCCESS,
    FETCH_PACKAGESCUPS_FAILURE,

} from './constants';

const ticketReducer = (state = StoresState, action) => {

    const type = action.type;
    const payload = action.payload;
    switch (type) {

        case FETCH_PACKAGES: {

            return {
                ...state,
                // packages: [],
                refreshPackages: true
            }
        }

        case FETCH_PACKAGES_SUCCESS: {
            return {
                ...state,
                // packages: payload?.data?.package,
                packages: payload?.options?.loadmore ? [
                    ...state.faq,
                    ...payload?.packages?.data?.package
                ] : payload?.packages?.data?.package,
                packagesTotalPage: payload?.packages?.data?.totalPages,
                packagesTypes: payload.packages?.data?.packagesTypes,
                packagesCurentPage: payload?.packages?.data?.currentPage,
                availableDates: payload?.packages.data.availableDates,
                refreshPackages: false
            }
        }

        case FETCH_PACKAGES_FAILURE: {
            return {
                ...state,
                refreshPackages: false
            }
        }

        case FETCH_SINGLEPACKAGES: {

            return {
                ...state,
                singlePackage: [],
                refreshSinglePackages: true
            }
        }

        case FETCH_SINGLEPACKAGES_SUCCESS: {
            return {
                ...state,
                singlePackage: payload?.data?.package,
                refreshSinglePackages: false
            }
        }

        case FETCH_SINGLEPACKAGES_FAILURE: {
            return {
                ...state,
                refreshSinglePackages: false
            }
        }

        case SEND_TICKET: {

            return {
                ...state,
                ticketDetail: [],
                refreshTicketDetail: true

            }
        }

        case SEND_TICKET_SUCCESS: {
            return {
                ...state,
                ticketDetail: payload?.data?.data,
                invoicePath: payload?.data?.data?.invoice_path ? false : true,
                refreshTicketDetail: false

            }
        }

        case SEND_TICKET_FAILURE: {
            return {
                ...state,
                ticketError: "error",
                refreshTicketDetail: false
            }
        }
        case ADD_CLIENT: {

            return {
                ...state,
                addClient: [],
                clientAdded: false,

            }
        }

        case ADD_CLIENT_SUCCESS: {

            return {
                ...state,
                addClient: payload?.data?.data,
                clientAdded: payload?.data,
                // clientAdded: true,
            }
        }

        case ADD_CLIENT_FAILURE: {
            return {
                ...state,
                clientAdded: false,
                ticketError: "error",
                clientError: payload?.data
            }
        }

        case RESET_CLIENT: {
            return {
                ...state,
                addClient: [],
                ticketUpdated: false,
                clientAdded: false,
                clientError: ""
            }
        }

        case UPDATE_TICKET: {

            return {
                ...state,
                updateTicket: [],
                ticketUpdated: false

            }
        }

        case UPDATE_TICKET_SUCCESS: {

            return {
                ...state,
                ticketStatus: payload?.data?.data,
                ticketUpdated: true
            }
        }

        case UPDATE_TICKET_FAILURE: {
            return {
                ...state,
                updateTicket: [],
                ticketError: "error",
                ticketUpdated: false
            }
        }

        case UPDATE_INVOICE: {

            return {
                ...state,
                invoiceStatus: ""
            }
        }

        case UPDATE_INVOICE_SUCCESS: {

            return {
                ...state,
                invoiceStatus: payload?.data?.data
            }
        }

        case UPDATE_INVOICE_FAILURE: {
            return {
                ...state,
                invoiceStatus: ""
            }
        }

        case APPLY_COUPON: {

            return {
                ...state,
                applyCoupon: [],
                CouponStatus: ""
            }
        }

        case APPLY_COUPON_SUCCESS: {

            return {
                ...state,
                applyCoupon: payload?.data,
                CouponStatus: "Apply"
            }
        }

        case APPLY_COUPON_FAILURE: {
            return {
                ...state,
                applyCoupon: [],
                CouponStatus: "notfound"
            }
        }

        case INVOICE_DETAIL: {

            return {
                ...state,
                invoiceDetail: [],
                invoiceTicketDetail: [],
                refreshInvoice: true,
                invoiceError: ""
            }
        }

        case INVOICE_DETAIL_SUCCESS: {
            return {
                ...state,
                invoiceDetail: payload?.data?.data,
                invoiceTicketDetail: payload?.data?.data?.tickets,
                refreshInvoice: false
            }
        }

        case INVOICE_DETAIL_FAILURE: {
            return {
                ...state,
                invoiceDetail: [],
                invoiceTicketDetail: [],
                refreshInvoice: false,
                invoiceError: true

            }
        }

        case FETCH_PACKAGESCUPS: {

            return {
                ...state,
                packagesCups: [],
                packageCup: [],
                refreshPackagesCups: true
            }
            break;
        }

        case  FETCH_PACKAGESCUPS_SUCCESS: {
            return {
                ...state,
                packagesCups: payload?.data?.packages,
                packageCup: payload?.data?.cup,
                refreshPackagesCups: false
            }
            break;
        }

        case FETCH_PACKAGESCUPS_FAILURE: {
            return {
                ...state,
                refreshPackagesCups: false
            }
            break;
        }

        default: {
            return {
                ...state
            }
        }


    }
}

export default ticketReducer;