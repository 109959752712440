import {
    fetchPackagesRequest,
    sendTicketRequest,
    addClientRequest,
    updateTicketRequest,
    updateInvoiceRequest,
    fetchSinglePackagesRequest,
    applyCouponRequest,
    invoiceDetailRequest,
    fetchPackagesCupsRequest,
    fetchPackagesFilterRequest
} from "./model";

import {
    FETCH_PACKAGES,
    FETCH_PACKAGES_SUCCESS,
    FETCH_PACKAGES_FAILURE,

    FETCH_SINGLEPACKAGES,
    FETCH_SINGLEPACKAGES_SUCCESS,
    FETCH_SINGLEPACKAGES_FAILURE,

    SEND_TICKET,
    SEND_TICKET_SUCCESS,
    SEND_TICKET_FAILURE,

    ADD_CLIENT,
    ADD_CLIENT_SUCCESS,
    ADD_CLIENT_FAILURE,

    RESET_CLIENT,

    UPDATE_TICKET,
    UPDATE_TICKET_SUCCESS,
    UPDATE_TICKET_FAILURE,

    UPDATE_INVOICE,
    UPDATE_INVOICE_SUCCESS,
    UPDATE_INVOICE_FAILURE,

    APPLY_COUPON,
    APPLY_COUPON_SUCCESS,
    APPLY_COUPON_FAILURE,

    INVOICE_DETAIL,
    INVOICE_DETAIL_SUCCESS,
    INVOICE_DETAIL_FAILURE,
    FETCH_PACKAGESCUPS,
    FETCH_PACKAGESCUPS_SUCCESS,
    FETCH_PACKAGESCUPS_FAILURE,


} from './constants';

const fetchPackages = (page, limit, options) => {
    return async (disptach) => {
        try {
            disptach({type: FETCH_PACKAGES, payload: {options}})

            const fetchPackagesResponse = await fetchPackagesRequest(page, limit);
            disptach({
                type: FETCH_PACKAGES_SUCCESS, payload: {packages: fetchPackagesResponse, options}
            });
        } catch (error) {

            disptach({type: FETCH_PACKAGES_FAILURE, payload: error.error});
        }
    }
}

export const fetchPackagesFilterYear = (page, limit, date, type, options) => {
    return async (disptach) => {
        try {
            disptach({type: FETCH_PACKAGES, payload: {options}})

            const fetchPackagesResponse = await fetchPackagesFilterRequest(page, limit, date, type);
            disptach({
                type: FETCH_PACKAGES_SUCCESS, payload: {packages: fetchPackagesResponse, options}
            });
        } catch (error) {

            disptach({type: FETCH_PACKAGES_FAILURE, payload: error.error});
        }
    }
}

const fetchSinglePackages = (id) => {
    return async (disptach) => {
        try {
            disptach({type: FETCH_PACKAGES});

            const fetchSinglePackagesResponse = await fetchSinglePackagesRequest(id);
            disptach({type: FETCH_SINGLEPACKAGES_SUCCESS, payload: fetchSinglePackagesResponse});
        } catch (error) {

            disptach({type: FETCH_SINGLEPACKAGES_FAILURE, payload: error.error});
        }
    }
}

const sendTicket = (params) => {
    return async (disptach: Function) => {
        try {
            disptach({type: SEND_TICKET});
            const sendTicketResponse = await sendTicketRequest(params);
            disptach({type: SEND_TICKET_SUCCESS, payload: sendTicketResponse});
        } catch (error) {

            disptach({type: SEND_TICKET_FAILURE, payload: error.error});
        }
    }
}

const addClient = (params) => {
    return async (disptach: Function) => {
        try {
            disptach({type: ADD_CLIENT});

            const addClientResponse = await addClientRequest(params, true);
            disptach({type: ADD_CLIENT_SUCCESS, payload: addClientResponse});
        } catch (error) {

            disptach({type: ADD_CLIENT_FAILURE, payload: error.error});
        }
    }
}

const resetClient = () => {
    return {type: RESET_CLIENT};
}

const updateTicket = (params, lang) => {
    return async (disptach: Function) => {
        try {
            disptach({type: UPDATE_TICKET});

            const updateTicketResponse = await updateTicketRequest(params, lang, true);
            disptach({type: UPDATE_TICKET_SUCCESS, payload: updateTicketResponse});
        } catch (error) {

            disptach({type: UPDATE_TICKET_FAILURE, payload: error.error});
        }
    }
}

const updateInvoice = (params) => {
    return async (disptach: Function) => {
        try {
            disptach({type: UPDATE_INVOICE});
            const updateInvoiceResponse = await updateInvoiceRequest(params);
            disptach({type: UPDATE_INVOICE_SUCCESS, payload: updateInvoiceResponse});
        } catch (error) {

            disptach({type: UPDATE_INVOICE_FAILURE, payload: error.error});
        }
    }
}

const applyCoupon = (params) => {
    return async (disptach: Function) => {
        try {
            disptach({type: APPLY_COUPON});
            const applyCouponResponse = await applyCouponRequest(params, true);
            disptach({type: APPLY_COUPON_SUCCESS, payload: applyCouponResponse});
        } catch (error) {

            disptach({type: APPLY_COUPON_FAILURE, payload: error.error});
        }
    }
}

const invoiceDetail = (params) => {
    return async (disptach: Function) => {
        try {
            disptach({type: INVOICE_DETAIL});
            const invoiceDetailsResponse = await invoiceDetailRequest(params);
            disptach({type: INVOICE_DETAIL_SUCCESS, payload: invoiceDetailsResponse});
        } catch (error) {

            disptach({type: INVOICE_DETAIL_FAILURE, payload: error});
        }
    }
}

const fetchPackagesCups = (id) => {
    return async (disptach) => {
        try {
            disptach({type: FETCH_PACKAGESCUPS});
            const fetchPackagesCupsResponse = await fetchPackagesCupsRequest(id);
            disptach({type: FETCH_PACKAGESCUPS_SUCCESS, payload: fetchPackagesCupsResponse});
        } catch (error) {

            disptach({type: FETCH_PACKAGESCUPS_FAILURE, payload: error.error});
        }
    }
}


const resetCoupon = () => {
    return {type: APPLY_COUPON};
}


export {
    fetchPackages,
    fetchSinglePackages,
    addClient,
    sendTicket,
    resetClient,
    updateTicket,
    updateInvoice,
    applyCoupon,
    resetCoupon,
    invoiceDetail,
    fetchPackagesCups
}