/**
 * Created by ITShpere on 11/22/2021.
 */
import {
    GET_MEDIA_VIDEOS_SUCCESS,
    GET_MEDIA_VIDEOS_FAILURE,
    SEARCH_MEDIA_VIDEOS
} from './constants';

const initState = {
    media_videos: [],
};

const videoPageReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_MEDIA_VIDEOS_SUCCESS:
            return {
                ...state,
                media_videos: action.payload
            };
            break;
        case GET_MEDIA_VIDEOS_FAILURE:
            return {
                ...state,
                media_videos: []
            }
            break;
        case SEARCH_MEDIA_VIDEOS:
            return {
                ...state,
                media_videos: action.payload
            };
            break;
        default :
            return {};
            break;
    }
    return state;
};

export default videoPageReducer;