export default {
    baseurl: process.env.REACT_APP_API_URL || 'http://api.frusiya-ticketing.beta.dev.sa:5050/',
    ticketurl: process.env.REACT_APP_TICKETAPI_URL || 'https://flone.troylab.org/api/',
    mastercard:process.env.REACT_APP_PAYMENT || 'test',
    // baseurl: 'http://api.thesaudicup.beta.dafa.sa:5001/',
    // baseurl: 'http://api.thesaudicup.beta.dafa.sa:5001/',
    // baseurl: 'http://api.thesaudicup.dev.dafa.sa:5000/',

    FAKE_DATA_BASE_URL : 'http://localhost:3004',
    LIVE_BASE_URL : 'api.frusiya-ticketing.dev.dafa.sa:5050/',
}
