/**
 * Created by ITShpere on 11/4/2021.
 */
import {
    GET_CONTENDERS_SUCCESS,
    GET_CONTENDER_LIFE_STATS_SUCCESS,
    GET_CONTENDER_LIFE_STATS_FAILURE,
    GET_CONTENDERS_FAILURE

} from './constants';

const initState = {
    contenders: [],
    life_stats: []
};

const contendersReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_CONTENDERS_SUCCESS:
            return {
                ...state,
                contenders: action.payload
            };
            break;
        case GET_CONTENDERS_FAILURE:
            return {
                ...state,
                contenders: []
            };
            break;
        case GET_CONTENDER_LIFE_STATS_SUCCESS:
            return {
                ...state,
                life_stats: action.payload
            }
            break;
        case GET_CONTENDER_LIFE_STATS_FAILURE:
            return {
                ...state,
                life_stats: []
            }
            break;
        default:
            return {};
            break;
    }
    return state;
};

export default contendersReducer;
