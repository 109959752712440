/**
 * Created by ITShpere on 11/4/2021.
 */

export const GET_CONTENDERS = "GET_CONTENDERS";
export const GET_CONTENDERS_SUCCESS = "GET_CONTENDERS_SUCCESS";
export const GET_CONTENDERS_FAILURE = "GET_CONTENDERS_FAILURE";

export const GET_CONTENDER_LIFE_STATS = "GET_CONTENDER_LIFE_STATS";
export const GET_CONTENDER_LIFE_STATS_SUCCESS = "GET_CONTENDER_LIFE_STATS_SUCCESS";
export const GET_CONTENDER_LIFE_STATS_FAILURE = "GET_CONTENDER_LIFE_STATS_FAILURE";


