import StoresState from './state';

import {
    CREATE_TELER_PAYMENT,
    CREATE_TELER_PAYMENT_SUCCESS,
    CREATE_TELER_PAYMENT_FAILURE,

    CHECK_TELER_PAYMENT,
    CHECK_TELER_PAYMENT_SUCCESS,
    CHECK_TELER_PAYMENT_FAILURE,

    PAYMENT_METHOD,
    PAYMENT_METHOD_SUCCESS,
    PAYMENT_METHOD_FAILURE
} from './constants';

const paymentReducer = ( state = StoresState, action) => {
    const type = action.type;
    const payload = action.payload;
    switch (type) {

        case CREATE_TELER_PAYMENT: {

            return {
                ...state,
                createTelerPayment: [],
                telerPaymentCreated: false
            }
        }

        case CREATE_TELER_PAYMENT_SUCCESS: {

            return {
                ...state,
                createTelerPayment: payload?.data,
                telerPaymentCreated:true
            }
        }

        case CREATE_TELER_PAYMENT_FAILURE: {
            return {
                ...state,
                createTelerPayment: [],
                telerPaymentCreated:false
            }
        }

        case CHECK_TELER_PAYMENT: {

            return {
                ...state,
                checkTelerPayment: [],
                createTelerPayment: [],
                telerPaymentCreated: false


            }
        }

        case CHECK_TELER_PAYMENT_SUCCESS: {

            return {
                ...state,
                checkTelerPayment: payload?.data,
            }
        }

        case CHECK_TELER_PAYMENT_FAILURE: {
            return {
                ...state,
                checkTelerPayment: [],
            }
        }

        case PAYMENT_METHOD: {

            return {
                ...state,
                // paymentMethod: "",
            }
        }

        case PAYMENT_METHOD_SUCCESS: {

            return {
                ...state,
                paymentMethod: payload?.data?.gateway,
            }
        }

        case PAYMENT_METHOD_FAILURE: {
            return {
                ...state,
            }
        }

        default: {
            return {
                ...state
            }
        }
    }
}

export default paymentReducer;