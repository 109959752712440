import {Fragment, useContext, useEffect} from "react";
import {fetchTerms} from "../redux/home/actions";
import {useDispatch} from "react-redux";
import {updateTicket} from "../redux/ticket/actions";
import {LangContext} from "../components/LangContext";
import {useParams} from "react-router-dom";
import ShowLoader from "../components/Loader/ShowLoader";

const TelerPayement = () => {
    const dispatch = useDispatch();
    const lang = useContext(LangContext)
    const { id } = useParams()

    useEffect(() => {
        dispatch(fetchTerms())

        dispatch(updateTicket({"invoice_id": id, "status": "success"}, lang));
        setTimeout(() => {
            const encoded = btoa(id);
            setTimeout(() => window.location.href = `/ticket/${encoded}`, 3000);
        }, 3000);
    }, [id])


    return (
        <Fragment>
            {ShowLoader()}
        </Fragment>
    )
}

export default TelerPayement;