

const FETCH_CITIES = "[CITIES] fetch";
const FETCH_CITIES_SUCCESS = "[CITIES] fetch success";
const FETCH_CITIES_FAILURE = "[CITIES] fetch failure";

const FETCH_TERMS = "[TERMS] fetch";
const FETCH_TERMS_SUCCESS = "[TERMS] fetch success";
const FETCH_TERMS_FAILURE = "[TERMS] fetch failure";

const FETCH_CITYCUPS = "[CITYCUPS] fetch";
const FETCH_CITYCUPS_SUCCESS = "[CITYCUPS] fetch success";
const FETCH_CITYCUPS_FAILURE = "[CITYCUPS] fetch failure";

const FETCH_EVENTS = "[EVENTS] fetch";
const FETCH_EVENTS_SUCCESS = "[EVENTS] fetch success";
const FETCH_EVENTS_FAILURE = "[EVENTS] fetch failure";

const FETCH_EVENTSEARCH = "[EVENTSEARCH] fetch";
const FETCH_EVENTSEARCH_SUCCESS = "[EVENTSEARCH] fetch success";
const FETCH_EVENTSEARCH_FAILURE = "[EVENTSEARCH] fetch failure";

export const FETCH_CITIESSEARCH = "[CITIESSEARCH] fetch";
export const FETCH_CITIESSEARCH_SUCCESS = "[CITIESSEARCH] fetch success";
export const FETCH_CITIESSEARCH_FAILURE = "[CITIESSEARCH] fetch failure";

export const FETCH_PACKAGESSEARCH = "[PACKAGESSEARCH] fetch";
export const FETCH_PACKAGESSEARCH_SUCCESS = "[PACKAGESSEARCH] fetch success";
export const FETCH_PACKAGESSEARCH_FAILURE = "[PACKAGESSEARCH] fetch failure";


const FETCH_SINGLEEVENT = "[SINGLEEVENT] fetch";
const FETCH_SINGLEEVENT_SUCCESS = "[SINGLEEVENT] fetch success";
const FETCH_SINGLEEVENT_FAILURE = "[SINGLEEVENT] fetch failure";


const FETCH_SLIDERS = "[SLIDERS] fetch";
const FETCH_SLIDERS_SUCCESS = "[SLIDERS] fetch success";
const FETCH_SLIDERS_FAILURE = "[SLIDERS] fetch failure";


const CREATE_PAYMENT ="[PAYMENT] create payment";
const CREATE_PAYMENT_SUCCESS ="[PAYMENT] create payment success";
const CREATE_PAYMENT_FAILURE ="[PAYMENT] create payment failure";


const FETCH_ABOUTUS = "[ABOUTUS] fetch";
const FETCH_ABOUTUS_SUCCESS = "[ABOUTUS] fetch success";
const FETCH_ABOUTUS_FAILURE = "[ABOUTUS] fetch failure";

export const FETCH_CUPSHERO = "[CUPSHERO] fetch";
export const FETCH_CUPSHERO_SUCCESS = "[CUPSHERO] fetch success";
export const FETCH_CUPSHERO_FAILURE = "[CUPSHERO] fetch failure";


export const FETCH_PACKAGEHERO = "[PACKAGEHERO] fetch";
export const FETCH_PACKAGEHERO_SUCCESS = "[PACKAGEHERO] fetch success";
export const FETCH_PACKAGEHERO_FAILURE = "[PACKAGEHERO] fetch failure";

export const FETCH_CITYHERO = "[CITYHERO] fetch";
export const FETCH_CITYHERO_SUCCESS = "[CITYHERO] fetch success";
export const FETCH_CITYHERO_FAILURE = "[CITYHERO] fetch failure";




export {

    FETCH_CITIES,
    FETCH_CITIES_SUCCESS,
    FETCH_CITIES_FAILURE,

    FETCH_TERMS,
    FETCH_TERMS_SUCCESS,
    FETCH_TERMS_FAILURE,

    FETCH_CITYCUPS,
    FETCH_CITYCUPS_SUCCESS,
    FETCH_CITYCUPS_FAILURE,

    FETCH_EVENTS,
    FETCH_EVENTS_SUCCESS,
    FETCH_EVENTS_FAILURE,

    FETCH_EVENTSEARCH,
    FETCH_EVENTSEARCH_SUCCESS,
    FETCH_EVENTSEARCH_FAILURE,

    FETCH_SINGLEEVENT,
    FETCH_SINGLEEVENT_SUCCESS,
    FETCH_SINGLEEVENT_FAILURE,

    FETCH_SLIDERS,
    FETCH_SLIDERS_SUCCESS,
    FETCH_SLIDERS_FAILURE,

    CREATE_PAYMENT,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_FAILURE,

    FETCH_ABOUTUS,
    FETCH_ABOUTUS_SUCCESS,
    FETCH_ABOUTUS_FAILURE,

    

};