import {

    FETCH_CITIES,
    FETCH_CITIES_SUCCESS,
    FETCH_CITIES_FAILURE,

    FETCH_TERMS,
    FETCH_TERMS_SUCCESS,
    FETCH_TERMS_FAILURE,

    FETCH_CITYCUPS,
    FETCH_CITYCUPS_SUCCESS,
    FETCH_CITYCUPS_FAILURE,

    FETCH_EVENTS,
    FETCH_EVENTS_SUCCESS,
    FETCH_EVENTS_FAILURE,

    FETCH_EVENTSEARCH,
    FETCH_EVENTSEARCH_SUCCESS,
    FETCH_EVENTSEARCH_FAILURE,

    FETCH_CITIESSEARCH,
    FETCH_CITIESSEARCH_SUCCESS,
    FETCH_CITIESSEARCH_FAILURE,

    FETCH_PACKAGESSEARCH,
    FETCH_PACKAGESSEARCH_SUCCESS,
    FETCH_PACKAGESSEARCH_FAILURE,

    FETCH_SINGLEEVENT,
    FETCH_SINGLEEVENT_SUCCESS,
    FETCH_SINGLEEVENT_FAILURE,

    FETCH_SLIDERS,
    FETCH_SLIDERS_SUCCESS,
    FETCH_SLIDERS_FAILURE,

    CREATE_PAYMENT,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_FAILURE,

    FETCH_ABOUTUS,
    FETCH_ABOUTUS_SUCCESS,
    FETCH_ABOUTUS_FAILURE,

    FETCH_CUPSHERO,
    FETCH_CUPSHERO_SUCCESS,
    FETCH_CUPSHERO_FAILURE,

    FETCH_PACKAGEHERO,
    FETCH_PACKAGEHERO_SUCCESS,
    FETCH_PACKAGEHERO_FAILURE,

    FETCH_CITYHERO,
    FETCH_CITYHERO_SUCCESS,
    FETCH_CITYHERO_FAILURE

} from './constants';

import {
    fetchCitiesRequest,
    fetchCityCupsRequest,
    fetchTermsRequest,
    fetchSlidersRequest,
    createPaymentRequest,
    fetchEventsRequest,
    fetchSingleEventRequest,
    fetchEventSearchRequest,
    fetchCitiesSearchRequest,
    fetchPackagesSearchRequest,
    fetchAboutUsRequest,
    fetchCupsHeroRequest,
    fetchCityHeroRequest,
    fetchPackageHeroRequest

} from './model';



const fetchCities = (page, limit, options) => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_CITIES , payload: { options } })
            const fetchCitiesResponse = await fetchCitiesRequest(page, limit);
            disptach({ type: FETCH_CITIES_SUCCESS, payload:{cities:fetchCitiesResponse,options}
            });
        } catch (error) {

            disptach({ type: FETCH_CITIES_FAILURE, payload: error.error });
        }
    }
}

const fetchTerms = (options) => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_TERMS , payload: { options } })
            const fetchTermsResponse = await fetchTermsRequest();
            disptach({ type: FETCH_TERMS_SUCCESS, payload:{terms:fetchTermsResponse,options}
            });
        } catch (error) {

            disptach({ type: FETCH_TERMS_FAILURE, payload: error.error });
        }
    }
}

const fetchCityCups = (id) => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_CITYCUPS});
            const fetchCityCupsResponse = await fetchCityCupsRequest(id);
            disptach({ type: FETCH_CITYCUPS_SUCCESS, payload: fetchCityCupsResponse });
        } catch (error) {

            disptach({ type: FETCH_CITYCUPS_FAILURE, payload: error.error });
        }
    }
}

const fetchEvents = (page, limit, options) => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_EVENTS, payload: { options } })
            const fetchEventsResponse = await fetchEventsRequest(page, limit);
            disptach({ type: FETCH_EVENTS_SUCCESS, payload:{events:fetchEventsResponse,options}
            });
    } catch (error) {

        disptach({ type: FETCH_EVENTS_FAILURE, payload: error });
    }
}
}

const fetchEventSearch = (name,lang) => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_EVENTSEARCH});
            const fetchEventSearchResponse = await fetchEventSearchRequest(name,lang);
            disptach({ type: FETCH_EVENTSEARCH_SUCCESS, payload: fetchEventSearchResponse });
        } catch (error) {

            disptach({ type: FETCH_EVENTSEARCH_FAILURE, payload: error.error });
        }
    }
}

const fetchCitiesSearch = (name,lang) => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_CITIESSEARCH});
            const fetchCitiesSearchResponse = await fetchCitiesSearchRequest(name,lang);
            disptach({ type: FETCH_CITIESSEARCH_SUCCESS, payload: fetchCitiesSearchResponse });
        } catch (error) {

            disptach({ type: FETCH_CITIESSEARCH_FAILURE, payload: error.error });
        }
    }
}

const fetchPackagesSearch = (name,lang) => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_PACKAGESSEARCH});
            const fetchPackagesSearchResponse = await fetchPackagesSearchRequest(name,lang);
            disptach({ type: FETCH_PACKAGESSEARCH_SUCCESS, payload: fetchPackagesSearchResponse });
        } catch (error) {

            disptach({ type: FETCH_PACKAGESSEARCH_FAILURE, payload: error.error });
        }
    }
}

const fetchSingleEvent = (id) => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_SINGLEEVENT});
            const fetchSingleEventResponse = await fetchSingleEventRequest(id);
            disptach({ type: FETCH_SINGLEEVENT_SUCCESS, payload: fetchSingleEventResponse });
        } catch (error) {
            disptach({ type: FETCH_SINGLEEVENT_FAILURE, payload: error.error });
        }
    }
}


const fetchSliders = () => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_SLIDERS });
            const fetchSlidersResponse = await fetchSlidersRequest();
            disptach({ type: FETCH_SLIDERS_SUCCESS, payload: fetchSlidersResponse });
        } catch (error) {

            disptach({ type: FETCH_SLIDERS_FAILURE, payload: error.error });
        }
    }
}


const createPayment = (params) => {
    return async (disptach: Function) => {
        try {
            disptach({ type: CREATE_PAYMENT });

            const createPaymentResponse = await createPaymentRequest(params, true);

            disptach({ type: CREATE_PAYMENT_SUCCESS, payload: createPaymentResponse });
        } catch (error) {

            disptach({ type: CREATE_PAYMENT_FAILURE, payload: error.error });
        }
    }
}


const resetSearch = () => {
    return { type: FETCH_EVENTSEARCH   };
}

const fetchAboutUs = () => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_ABOUTUS });
            const fetchAboutUsResponse = await fetchAboutUsRequest();
            disptach({ type: FETCH_ABOUTUS_SUCCESS, payload: fetchAboutUsResponse });
        } catch (error) {

            disptach({ type: FETCH_ABOUTUS_FAILURE, payload: error.error });
        }
    }
}

const fetchCupsHero = () => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_CUPSHERO });
            const fetchCupsHerosResponse = await fetchCupsHeroRequest();
            disptach({ type: FETCH_CUPSHERO_SUCCESS, payload: fetchCupsHerosResponse });
        } catch (error) {

            disptach({ type: FETCH_CUPSHERO_FAILURE, payload: error.error });
        }
    }
}

const fetchPackagesHero = () => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_PACKAGEHERO });
            const fetchPackagesHerosResponse = await fetchPackageHeroRequest();
            disptach({ type: FETCH_PACKAGEHERO_SUCCESS, payload: fetchPackagesHerosResponse });
        } catch (error) {

            disptach({ type: FETCH_PACKAGEHERO_FAILURE, payload: error.error });
        }
    }
}

const fetchCityHero = () => {
    return async (disptach) => {
        try {
            disptach({ type: FETCH_CITYHERO });
            const fetchCityHeroResponse = await fetchCityHeroRequest();
            disptach({ type: FETCH_CITYHERO_SUCCESS, payload: fetchCityHeroResponse });
        } catch (error) {

            disptach({ type: FETCH_CITYHERO_FAILURE, payload: error.error });
        }
    }
}




export {
    fetchCities,
    fetchCityCups,
    fetchTerms,
    fetchSliders,
    createPayment,
    fetchEvents,
    fetchSingleEvent,
    fetchEventSearch,
    fetchCitiesSearch,
    fetchPackagesSearch,
    resetSearch,
    fetchAboutUs,
    fetchCupsHero,
    fetchCityHero,
    fetchPackagesHero
}

