const TicketState = {
    singlePackage: [],
    refreshSinglePackages:false,
    packages: [],
    packagesTotalPage: 0,
    packagesCurentPage: 0,
    refreshPackages:false,
    ticketDetail:[],
    refreshTicketDetail:false,
    ticketError:"",
    addClient: [],
    clientAdded:false,
    clientError:'',
    updateTicket:[],
    ticketUpdated:false,
    ticketStatus: [],
    invoiceStatus: "",
    applyCoupon: [],
    CouponStatus: "",
    invoiceDetail:[],
    refreshInvoice: false,
    invoiceError:"",
    invoiceTicketDetail:[],
    invoicePath: false,
    packagesCups: [],
    packageCup:[],
    refreshPackagesCups:false,
 }
 export default TicketState;