import axios from 'axios';
import CFG from '../constant/CFG';
import swal from 'sweetalert';

const request = async ({
    url,
    method,
    params,
    headers,
}) => {
    try {
        const fullURL = `${CFG.baseurl}${url}`;
        return new Promise((resolve, reject) => {
            const timeOutId =  setTimeout(() => {
                console.error('Timeout, Server is not responding')
                reject(new Error('Timeout, Server is not responding'))
                // reject()
            }, 15000) // After 10 seconds if there is no response from API the request will stopped.

            let modfiedHeaders = {
                ...headers,
                "Accept":"*/*"
            };

            axios[method](fullURL, method !== "get" ? params : {headers: modfiedHeaders}, { headers: modfiedHeaders }).then((response) => {
                clearTimeout(timeOutId)
                resolve(response);
            }).catch((error) => {
                clearTimeout(timeOutId)
                reject({
                    status: error?.response?.status,
                    error: error?.response,
                    isError: true
                });
            });

        });
    } catch (error) {
        console.log({ error });
    }
}

export default request;