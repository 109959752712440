const FETCH_PACKAGES = "[PACKAGES] fetch";
const FETCH_PACKAGES_SUCCESS = "[PACKAGES] fetch success";
const FETCH_PACKAGES_FAILURE = "[PACKAGES] fetch failure";

const FETCH_SINGLEPACKAGES = "[SINGLEPACKAGES] fetch";
const FETCH_SINGLEPACKAGES_SUCCESS = "[SINGLEPACKAGES] fetch success";
const FETCH_SINGLEPACKAGES_FAILURE = "[SINGLEPACKAGES] fetch failure";

const SEND_TICKET = "[Ticket Details] send ";
const SEND_TICKET_SUCCESS = "[Ticket Details] send ticket success";
const SEND_TICKET_FAILURE = "[Ticket Details] send ticket failure";

const ADD_CLIENT ="[CLIENT] send client";
const ADD_CLIENT_SUCCESS ="[CLIENT] send client success";
const ADD_CLIENT_FAILURE ="[CLIENT] send client failure";

const RESET_CLIENT = "[CLIENT] reset ";


const UPDATE_TICKET ="[UPDATE] update ticket";
const UPDATE_TICKET_SUCCESS ="[UPDATE] update ticket success";
const UPDATE_TICKET_FAILURE ="[UPDATE] update ticket failure";

const UPDATE_INVOICE ="[INVOICE] update ticket";
const UPDATE_INVOICE_SUCCESS ="[INVOICE] update ticket success";
const UPDATE_INVOICE_FAILURE ="[INVOICE] update ticket failure";

const APPLY_COUPON ="[INVOICE] apply coupon";
const APPLY_COUPON_SUCCESS ="[INVOICE] apply coupon success";
const APPLY_COUPON_FAILURE ="[INVOICE] apply coupon failure";

const CREATE_TELER_PAYMENT ="[PAYMENT] create payment";
const CREATE_TELER_PAYMENT_SUCCESS ="[PAYMENT] create payment success";
const CREATE_TELER_PAYMENT_FAILURE ="[PAYMENT] create payment failure";

const CHECK_TELER_PAYMENT ="[PAYMENT] check payment";
const CHECK_TELER_PAYMENT_SUCCESS ="[PAYMENT] check payment success";
const CHECK_TELER_PAYMENT_FAILURE ="[PAYMENT] check payment failure";

const INVOICE_DETAIL ="[INVOICEDETAIL] invoice detail";
const INVOICE_DETAIL_SUCCESS ="[INVOICEDETAIL]  invoice detail success";
const INVOICE_DETAIL_FAILURE ="[INVOICEDETAIL]  invoice detail failure";

const PAYMENT_METHOD ="[PAYMENT_METHOD] payment method";
const PAYMENT_METHOD_SUCCESS ="[PAYMENT_METHOD] payment method success";
const PAYMENT_METHOD_FAILURE ="[PAYMENT_METHOD]  payment method failure";

const FETCH_PACKAGESCUPS = "[PACKAGESCUPS] fetch";
const FETCH_PACKAGESCUPS_SUCCESS = "[PACKAGESCUPS] fetch success";
const FETCH_PACKAGESCUPS_FAILURE = "[PACKAGESCUPS] fetch failure";

export {
    FETCH_PACKAGES,
    FETCH_PACKAGES_SUCCESS,
    FETCH_PACKAGES_FAILURE,

    FETCH_SINGLEPACKAGES,
    FETCH_SINGLEPACKAGES_SUCCESS,
    FETCH_SINGLEPACKAGES_FAILURE,

    SEND_TICKET,
    SEND_TICKET_SUCCESS,
    SEND_TICKET_FAILURE,

    ADD_CLIENT,
    ADD_CLIENT_SUCCESS,
    ADD_CLIENT_FAILURE,

    RESET_CLIENT,

    UPDATE_TICKET,
    UPDATE_TICKET_SUCCESS,
    UPDATE_TICKET_FAILURE,

    UPDATE_INVOICE,
    UPDATE_INVOICE_SUCCESS,
    UPDATE_INVOICE_FAILURE,

    APPLY_COUPON,
    APPLY_COUPON_SUCCESS,
    APPLY_COUPON_FAILURE,

    INVOICE_DETAIL,
    INVOICE_DETAIL_SUCCESS,
    INVOICE_DETAIL_FAILURE,

    FETCH_PACKAGESCUPS,
    FETCH_PACKAGESCUPS_SUCCESS,
    FETCH_PACKAGESCUPS_FAILURE,

};